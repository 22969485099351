.sidebar{
    height: 100%; 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: -1;
    background-color:#252529;
    width:265px; 
    overflow:auto;
    align-items: center;
  }