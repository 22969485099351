@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}

html {
  padding:0px;
  margin:0px;
  height:100%;
}
#root{
  height:100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body{font-family: 'Roboto', sans-serif;}

.appContainer{
  padding:0px;
  margin:0px;
  height:100%;
  box-sizing:border-box;
}

.searchHolder{
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 350px;
  padding: 5px 10px 5px 10px;
  margin-left:auto;
  background-color: #378e81;
  box-sizing:border-box;
  margin-right:25px;
}

.searchHolder input{
  width:290px;
  color:#ffffff; 
  padding-left:10px;
}

.searchHolder .searchIcon{
  margin-top:2px;
}

.contentContainer{
    display: flex;
    overflow: hidden;
    border-radius: 0px;
    flex-direction: column;
    border: none;
    width: 100%;
    align-items: stretch;
}

.fullHeight{
  height:100%;
}

.rotate-90 {
  transform: rotate(90deg);
}

.modalBoxMultipleSelect{
  margin-top:20px;
}

.verticalMenu{
  display: 'flex' !important;
  width: 10% !important
}

.baseContainer{
  display:flex;
  flex-direction: column;
  height: 100%;
  background-color: #FBFBFA;
}

.toastSuccess{
  background-color: #34aa44 !important
}

.toastError{
  background-color: #e6492d !important
}

.toastErrorBody > div, .toastSuccessBody > div {
    height: auto
}

.Toastify__toast{
    min-height: 50px !important
}

.Toastify__close-button{
    margin-top: 9px;
    padding: 0px 12px 0px 10px;
    font-weight: normal
}

.Toastify__toast-container{
    width: 360px !important
}
.loginContainer{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position:fixed;
    height:100%;
    width:100%;
    background-color:#fafafa;
    top:0px;
    left:0px;
}

.login{
    width:400px;
    height:375px;
    border: 1px solid #eeeeee;
    padding: 30px;
    box-sizing:border-box;
    background-color:#ffffff;
    display:flex;
    align-items:center;
    flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.loginInput{
    width:100%;
    margin-top:10px !important;
}

.loginButton{
    width:100%;
    margin-top:70px !important;
    height:40px;
}

.loginRememberMe{
    width:100%
}

.loginRememberMeLabel span{
    font-size:14px !important;
}
.sidebar{
    height: 100%; 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: -1;
    background-color:#252529;
    width:265px; 
    overflow:auto;
    align-items: center;
  }
.width-300{
    width:300px !important;
}

.noPadding{
    padding:0px !important;
}

.homeContainer{
    display:flex;
    flex-direction: column;
    flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
    overflow-y: auto;  /*adds scroll to this container*/
    padding:20px;
}

.calendarHolder{
    width:60%;
}

.homeEventsList{
    width: 40%;
    padding: 2px 0px 0px 0px;
    box-sizing:border-box;
}
