.width-300{
    width:300px !important;
}

.noPadding{
    padding:0px !important;
}

.homeContainer{
    display:flex;
    flex-direction: column;
    flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
    overflow-y: auto;  /*adds scroll to this container*/
    padding:20px;
}

.calendarHolder{
    width:60%;
}

.homeEventsList{
    width: 40%;
    padding: 2px 0px 0px 0px;
    box-sizing:border-box;
}